<template>
  <div>
    <admin-dashboard />
  </div>
</template>

<script>
import AdminDashboard from './admin-dashboard/AdminDashboard.vue'

export default {
  components: { AdminDashboard },
  data() {
    return {
    }
  },
}
</script>

<style>

</style>
